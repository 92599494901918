import React from 'react'

export const WorWithusMock = () => {
  return (
    <div className="workwithus-container">
      <p>
        Trabalhar no Compre Mais é fazer parte de uma empresa de sucesso. Mais
        do que isso, uma família.
      </p>
      <p>
        Dinamismo, ética e espírito empreendedor são características de nossos
        colaboradores.
      </p>
    </div>
  )
}
